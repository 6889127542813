<template>
  <div class="family" ref="family">
    <div v-if="dataList.length > 0">
      <div class="items" v-for="item in dataList" :key="item.id" @click="goUserInfo(item.bhrId,item.userId,item.houseId,item.relation,item.relationStr,item.location,item.isMyHouseFamily, item.nowLive)">

        <div class="down">
          <div class="down-left">
              <img v-if="item.imgUrl" class="header" :src="getImageStream(item.imgUrl)"/>
              <img v-else class="header" :src="require('@/assets/img/newVersion/header.png')"/>
              <div class="personal-info">
                <!-- <div class="community">{{item.location}}</div> -->
                <div class="community" :class="{'isOld32': $isOld == 1}">{{item.name}} <span class="realtion" style="color: #999999;font-weight: 'PingFangSC-Regular'">{{item.relationAB ? '(' + item.relationAB + ')' : ''}}</span></div>
                <div class="personal" :class="{'isOld32': $isOld == 1}">
                  <div class="sex">{{item.sexStr}}</div>
                  <div class="age">{{item.age}}</div>
                  <div class="mobile">{{desensitization(item.mobile)}}</div>
                </div>
                <div class="idNumber" :class="{'isOld32': $isOld == 1}">{{idCardDesensitization(item.idCard)}}</div>
              </div>
              <a :href="'tel:'+item.mobile" @click.stop=""><img class="phone" :src="require('@/assets/img/newVersion/mobile.png')"/></a>

          </div>
          <img class="down-right" :src="require('@/assets/img/newVersion/right.png')"/>
        </div>
        <div class="top">
          <div class="top-left">
            <div class="name" :class="{'isOld28': $isOld == 1}">{{item.location}}</div>
            <!-- <div class="realtion">（{{item.relationStr}}）</div> -->
          </div>
          <div class="top-right" :class="{'isOld28': $isOld == 1}" @click.stop="selectRecord(item.userId,item.name, item.bhrId)">搬离</div>
        </div>
      </div>
    </div>
    <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
    <div
      class="add-box"
      @click="goAdd(1)"
      @mousedown="down"
      @touchstart="down"
      @mousemove="move"
      @touchmove="move"
      @mouseup="end"
      @touchend="end"
      ref="fu"
      >
        <div class="add-img">
            <img class="add-icon" :src="require('@/assets/img/newVersion/add.png')" alt="">
            <div class="text">新增、添加</div>
        </div>
    </div>
    <van-dialog
      v-model="control.moveOutShow"
      cancel-button-text="取消"
      confirm-button-text="确定"
      show-confirm-button
      show-cancel-button
      @confirm="moveOut"
      :before-close="onBeforeClose"
      @cancel="control.moveOutShow = false">
      <div class="moveContent">
        确定搬出{{show.moveOutName}}?
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {getImageStream} from '@/utils'
import {desensitization,idCardDesensitization} from '@/utils/utils'
export default {
  name: 'familyManegement',
  data() {
    return {
      moveOutId: '',
      dataList: [],
      show: {
        moveOutName: ''
      },
      control: {
        moveOutShow: false,
      },
      flags: false, //控制使用
      position: {
        x: 0,
        y: 0,
      },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
      isTenant: false
    }
  },
  created() {
    this.getDataList()
    this.getHouse() // 判断是否为租客
  },
  methods: {
    getHouse () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/list'),
        method: 'GET',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          orgId: this.$orgId,
          loginUserId: this.$userId,
          loginUserOrgId: this.$orgId,
          userId: this.$userId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let temHouse = []
          temHouse = data.page.list.filter(item => item.relationship == 10 || item.relationship == 0)
          if (temHouse.length == data.page.list.length) {
            this.isTenant = true
          }
        }
      })
    },
    getImageStream(value) {
      return getImageStream(value)
    },
    down() {
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = this.$refs.fu.offsetLeft;
      this.dy = this.$refs.fu.offsetTop;
    },
    move() {
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
        let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
        this.xPum < 0 && (this.xPum = 0);
        this.yPum < 0 && (this.yPum = 0);
        this.xPum > width && (this.xPum = width);
        this.yPum > height && (this.yPum = height);
        this.$refs.fu.style.left = this.xPum + "px";
        this.$refs.fu.style.top = this.yPum + "px";
        //阻止页面的滑动默认事件
        document.addEventListener("touchmove",function () {
          event.preventDefault();
        },false);
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
    },
    desensitization(value) {
      return desensitization(value)
    },
    idCardDesensitization(value) {
      return idCardDesensitization(value)
    },
    getDataList() {
      // this.page++
      this.$toast.loading({duration: 0, message: "加载中...",forbidClick: true,});
      this.$http({
          url: this.$http.adornUrl('/wxapp/building/house/jmgg/family'),
          method: 'GET',
          params: this.$http.adornParams({
            page: 1,
            limit: -1,
            communityId: this.$orgId,
            userId: this.$userId
          })
      }).then(({data}) => {
        this.$toast.clear()
        if(data && data.code === 0) {
          this.dataList = data.familyList
        }else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 根据返回是否弹出提醒
    selectRecord (id,name,bhrId) {
      this.moveOutId = id
      this.show.moveOutName = name
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/selectRecordByUserId`),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.$globalData.userInfo.orgId,
          userId: parseInt(id),
          type: 8
        })
      }).then(({data}) => {
        if (data && data.code == 0) {
          if(data.data == null) {
            this.moveOut(id, name,bhrId)
          } else if (data.data && data.data.createTime) {
            this.$toast({
              message: `您于${data.data.createTime}已经提交搬离申请！`,
              duration: 2000,
              forbidClick: true
            })
          }
        } else {
          that.$toast.fail(data.msg)
        }
      })
    },
    onBeforeClose (action, done) {
      if (action === "confirm") {
          return done(false);
      } else {
          return done();
      }
    },
    moveOut(id,name,bhrId) {
      // this.$dialog({
      //   message: `确认迁出${name}？`,
      //   confirmButtonText: '确认',
      //   showCancelButton: true,
      //   cancelButtonText:'取消',
      // }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/user/res/houseRes/moveOut'),
          method: 'POST',
          params: this.$http.adornParams({
            id: bhrId,
            modelType: this.isTenant ? 1 : 2,
            operator: this.$userId,
            checkType: this.$globalData.userInfo.assistId ? 10 : this.isTenant ? 12 : 9, //居民9 协管员搬离10
          })
        }).then(({data}) => {
          if(data && data.code === 0) {
            this.$toast.success({
              message: '操作成功',
              duration: 1500,
            }),
            setTimeout(() => {
              this.page = 0
              this.dataList = []
              this.getDataList()
            },1500)
          }
        })
      // })
    },
    goAdd(isSelf) {
      this.$router.push({path: '/newRegister',query: {isSelf,title: '添加家人',myHouse: 1,isList: 1, isFamily: 1}})
    },
    goUserInfo(bhrId,peopleId,houseId,relationship,relationshipStr,houseName,isMyHouseFamily,nowLive) {
      this.$router.push({
        path: '/peopleAdd',
        query: {
          bhrId,
          peopleId,
          houseId,
          relationship,
          relationshipStr,
          houseName,
          isMyHouseFamily,
          title: '添加家人',
          myHouse: 1,
          nowLive,
          isFamily: 1
        }
      })
    }
  },

}
</script>

<style scoped>
.van-dialog {
  width: 560px;
  border-radius: 16px;
}
.van-dialog >>> .van-dialog__content {
  margin: 38px 0px 40px 0px;
  text-align: center;
}
.moveContent {
  font-size: 30px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #333333;
}
</style>
<style lang="scss" scoped>
// 去除滑动式控制台报警告
 * {
  touch-action: pan-y;
}
.family {
  width: 750px;
  min-height: 100vh;
  padding-top: 22px;
  // position: fixed;
  // top: 0;
  // left: 0;
}
.items {
  /* width: 750rpx; */
  height: 282px;
  background: #FFFFFF;
  padding: 0 30px;
  margin-bottom: 16px;
  padding-top: 24px;
}
.top {
  height: 80px;
  // line-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid rgba(0,0,0,0.1);
}
.top-left {
  display: flex;
  // font-size: 30px;
  font-weight: 600;
  font-size: 24px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #999999;
}
.top-left .name {
  width: 500px;
  color: #333333;
}
.top-left .realtion {
  color: #999999;
}
.top-right {
  width: 136px;
  height: 52px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 2px solid #4581F8;
  font-size: 24px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #4581F8;
  text-align: center;
  line-height: 52px;
}
.down {
  height: calc(100% - 80px);
  // margin-top: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 26px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.down-left {
  display: flex;
  align-items: center;
}
.header {
  width: 120px;
  height: 120px;
}
.personal-info {
  margin-left: 16px;
  margin-right: 26px;
}
.community {
  font-size: 28px;
  font-family: 'PingFangSC-Semibold';
  font-weight: 600;
  color: #333333;
}
.personal {
  font-size: 28px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #666666;
  display: flex;
  margin: 10px 0;
}
.age {
  margin: 0 20px;
}
.personal .mobile {
  color: #4581F8;
}
.idNumber {
  font-size: 28px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #666666;
}
.down-left .phone {
  width: 40px;
  height: 40px;
}
.down-right {
  width: 16px;
  height: 28px;
}
.add-box {
    position: fixed;
    // position: absolute;
    bottom: 72px;
    right: 0;
    width: 206px;
    height: 214px;
    user-select: none; /* 不可选中,为了拖拽时不让文字高亮 */
    .add-img {
        position: relative;
        .add-icon {
            width: 100%;
            height: 100%;
        }
        .text {
            position: absolute;
            left: 56px;
            bottom: 52px;
            font-size: 20px;
            // font-family: PingFangSC-Medium;
            font-weight: 500;
            color: #FFFFFF;
        }
    }

}
</style>
